import JSZip from 'jszip'
import store from '@/store'

// 序列化所有 mod 数据
export function serializeMod () {
  const payload = {
    artifacts: store.state.mod.artifacts || [],
    units: store.state.mod.units || []
  }
  return JSON.stringify(payload)
}
// 反序列化所有 mod 数据
export function deserializeMod (payload) {
  const { artifacts, units } = JSON.parse(payload)
  store.commit('mod/setArtifacts', artifacts)
  store.commit('mod/setUnits', units)
}

// string[]
export function formatText (textList) {
  return textList.reduce((text, next) => {
    const valText = Array.isArray(next) ? next.join('\n') : next
    return text + `
${valText}

`
  }, '')
}

// [{ name: string, data: string, image: string(base64) }] 这是基本格式，特殊案例可以加上比如 factionUnit 这样的标识
export function withFolder (folderName, fileInfos, options = {}) {
  options = {
    fileAppend: 'data',
    imageAppend: 'sprite',
    ...options
  }
  return (zip) => {
    const folder = zip.folder(folderName)
    fileInfos.forEach(file => {
      folder.file(`${file.name} ${options.fileAppend}.txt`, file.data)
      folder.file(`${file.name} ${options.imageAppend}.png`, file.image, {
        base64: true
      })
      if (file.factionUnit) {
        folder.file(`${file.name}+ ${options.imageAppend}.png`, file.plusImage, {
          base64: true
        })
      }
    })
    return folder
  }
}

export function dataUrlToBase64 (dataUrl) {
  return dataUrl.replace(/^data:image\/(png|jpg);base64,/, '')
}

// 生成神器模组格式
export function toArtifactModule (artifacts) {
  const artifactInfos = artifacts.map(artifact => {
    const { name, image, slot, quality, firstBonusSize, firstBonusEffect, secondBonusSize, secondBonusEffect, part, oriented } = artifact
    // 需要顺序
    const list = [name, slot, quality, firstBonusSize, firstBonusEffect, secondBonusSize, secondBonusEffect, part, oriented]
    return {
      name,
      data: formatText(list),
      // dataURL -> Base64
      image: dataUrlToBase64(image)
    }
  })
  return withFolder('Artifacts', artifactInfos)
}

// 生成单位模组格式
export function toUnitModule (units) {
  const unitInfos = units.map(unit => {
    const {
      name,
      plusName,
      image,
      plusImage,
      goldCost,
      weeklyGrowth,
      rareResourceCount,
      rareResourceType,
      balanceModifier,
      plusBalanceModifier,
      abilities,
      plusAbilities,
      spells,
      plusSpells,
      techAbilities,
      techPlusAbilities,
      sound,
      attackType,
      living,
      link,
      unitGroups,
      isNeutral
    } = unit
    // 中立生物
    if (isNeutral) {
      // 需要顺序
      const allAbilities = [...abilities, ...spells, ...techAbilities]
      const list = [name, goldCost, balanceModifier, allAbilities, sound, attackType, living, link, unitGroups]
      return {
        name,
        data: formatText(list),
        // dataURL -> Base64
        image: dataUrlToBase64(image)
      }
    }
    // 阵营生物
    const rareResourceCost = rareResourceCount > 0 ? `${rareResourceCount}${rareResourceType}` : '00'
    const allAbilities = [...abilities, ...spells, techAbilities]
    const allPlusAbilities = [...plusAbilities, ...plusSpells, ...techPlusAbilities]
    const list = [
      [name, plusName],
      goldCost,
      weeklyGrowth,
      rareResourceCost,
      [balanceModifier, plusBalanceModifier],
      allAbilities,
      allPlusAbilities,
      sound,
      attackType,
      living,
      link,
      unitGroups
    ]
    return {
      factionUnit: true,
      name,
      data: formatText(list),
      // dataURL -> Base64
      image: dataUrlToBase64(image),
      plusImage: dataUrlToBase64(plusImage)
    }
  })
  // 如果是阵营 Mod，要获取阵营名称来确定文件夹名
  return withFolder('Neutral units', unitInfos, {
    imageAppend: 'spritesheet'
  })
}

// 生成模组 zip 包
export function generateModZip (name) {
  const zip = new JSZip()
  const rootFolder = zip.folder(name)
  const mod = store.state.mod
  mod.artifacts.length && toArtifactModule(mod.artifacts)(rootFolder)
  mod.units.length && toUnitModule(mod.units)(rootFolder)
  return zip
}
