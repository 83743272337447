<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs
          class="rounded elevation-1"
          style="overflow: hidden"
          color="indigo"
          height="60px"
          grow
        >
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :to="tab.route"
            link
            exact-path
          >
            <v-badge
              color="orange"
              :value="!!tab.count"
              :content="tab.count"
            >
              <div class="text-subtitle-1">{{ tab.name }}</div>
            </v-badge>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-sheet
      class="my-4 py-2"
      rounded
      elevation="1"
    >
      <div class="d-flex align-center justify-space-between">
        <!-- Mod 名称 -->
        <v-text-field
          class="mx-2"
          v-model="modName"
          label="Mod 名称"
          placeholder="决定了打包后的 Mod 名称，暂不支持中文，默认为 MyMod"
          hide-details
          dense
          outlined
          required
        ></v-text-field>
        <!-- 工具栏 -->
        <!-- <v-btn color="primary" class="mx-2" outlined disabled @click="onImportBase64">
          <v-icon>mdi-download</v-icon>
          导入文本
        </v-btn> -->
        <v-file-input
          v-model="file"
          @change="onImportPlain"
          placeholder="导入文本"
          label="导入文本"
          prepend-icon="mdi-paperclip"
          accept=".txt"
          small-chips
          outlined
          dense
          hide-details
        >
          <template v-slot:selection="{ text }">
            <v-chip
              small
              label
              color="primary"
            >
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
        <v-btn color="primary" class="mx-2" outlined @click="onExportPlain">
          <v-icon>mdi-download</v-icon>
          导出文本
        </v-btn>
        <v-btn color="primary" class="mx-2" outlined @click="exportMod">
          <v-icon>mdi-download</v-icon>
          导出 mod
        </v-btn>
      </div>
    </v-sheet>
    <v-row>
      <v-scroll-x-reverse-transition mode="out-in">
        <router-view></router-view>
      </v-scroll-x-reverse-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { deserializeMod, serializeMod, generateModZip } from 'utils/mod'
import { download } from 'utils/index'

export default {
  data () {
    return {
      // 需要反序列化的文件
      file: null,
      modName: '',
      menus: [
        { title: '保存为 mod' },
        { title: '导出成文本' }
      ]
    }
  },
  computed: {
    ...mapState('mod', ['artifacts', 'units']),
    tabs () {
      const artifactCount = this.artifacts.length
      const unitCount = this.units.length
      return [
        { route: { name: 'Artifact' }, name: '神器', count: artifactCount },
        { route: { name: 'Unit' }, name: '单位', count: unitCount }
      ]
    },
    modNameWithDefault () {
      return this.modName || 'MyMod'
    }
  },
  methods: {
    exportMod () {
      const name = this.modNameWithDefault
      const zip = generateModZip(name)
      zip.generateAsync({ type: 'blob' }).then(blob => {
        download(blob, {
          type: 'application/zip',
          name: `${name}.zip`
        })
      })
    },
    onImportPlain () {
      // TODO: 需要加覆盖警告
      if (!this.file) {
        return
      }
      const fr = new FileReader()
      fr.onload = () => {
        deserializeMod(fr.result)
        this.$noti.success('导入成功')
      }
      fr.readAsText(this.file)
    },
    onExportPlain () {
      const json = serializeMod()
      download(new Blob([json]), {
        type: 'text/plain;charset=utf-8',
        name: `${this.modNameWithDefault}.txt`
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
